'use client';

import { useState } from 'react';

import { SigninFormConfigContextProvider } from '@/features/authentication/signin/providers';

import { SigninFlowConfig } from '@/shared/types';

export default function AuthenticationLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [signInConfig, setSignInConfig] = useState<SigninFlowConfig>({
    data: {
      username: '',
      passcode: '',
      otp: '',
      idToken: '',
      isSSO: false,
    },
    phoneNumberForm: {
      title: 'Log in to Moniepoint',
      subtitle: 'Continue using Google or your phone number',
      ssoEnabled: true,
      ctaText: 'Continue',
    },
    passcodeVerification: {
      title: 'Enter passcode',
      subtitle: 'Your secure 6-digit passcode',
      passcodeLength: 6,
    },
  });

  return (
    <SigninFormConfigContextProvider
      value={{ config: signInConfig, setConfig: setSignInConfig }}
    >
      {children}
    </SigninFormConfigContextProvider>
  );
}
